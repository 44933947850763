<section class="portfolio-section" id="work">
  <div class="container">
    <h2 class="portfolio-heading text-uppercase">Portfolio</h2>
    <div class="row">
      <div class="col-lg-8 col-sm-12 portfolio-img" data-aos="fade-right" data-aos-duration="1000">
        <img class="rounded thumbnail portfolio-img" src="../../../assets/img/portfolio-img1.jpg" alt="profile image">
      </div>
      <div class="col-lg-4 col-sm-12
         text-background col-padding" data-aos="fade-left" data-aos-duration="1000">
        <h2 class="work-heading">Deployment Dashboard</h2>
        <h3 class="business">HealthEquity Inc.</h3>
        <p class="work-para">A web application that enables teams to validate their deployment is meeting SDLC rules and
          regulations.</p>
        <a href="/#/deployment-dashboard" class="case-study-btn">View Case Study</a>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-8 col-sm-12 portfolio-img" data-aos="fade-right" data-aos-duration="1000">
        <img class="rounded thumbnail portfolio-img" src="../../../assets/img/server-monitor.png" alt="profile image">
      </div>
      <div class="col-lg-4 col-sm-12 col-padding" data-aos="fade-left" data-aos-duration="1000">
        <h2 class="work-heading">Server Monitor</h2>
        <h3 class="business">HealthEquity Inc.</h3>
        <p class="work-para">A web application that enables teams to monitor test agent servers for automation tests.
        </p>
        <a href="/#/server-monitor" class="case-study-btn">View Case Study</a>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-sm-12 portfolio-img" data-aos="fade-right" data-aos-duration="1000">
        <img class="rounded thumbnail portfolio-img" src="../../../assets/img/sib-cover.jpg" alt="profile image">
      </div>
      <div class="col-lg-4 col-sm-12 col-padding" data-aos="fade-left" data-aos-duration="1000">
        <h2 class="work-heading">Company Website</h2>
        <h3 class="business">Self Image Beauty</h3>
        <p class="work-para">Esthetics website. Allows users to learn about the company, book an appointment, etc.</p>
        <a href="/#/self-image-beauty" class="case-study-btn">View Case Study</a>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
