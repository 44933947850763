<section class="deploy-cs ">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-down" data-aos-offset="500" data-aos-duration="1000">
        <h1 class="deploy-head">Self Image Beauty</h1>
        <h2 class="problem">Project Brief:</h2>
        <h2 class="problem">Self Image Beauty is a beauty company that offers esthetic services and lash training
          courses.</h2>
        <h2 class="problem">The client needed a simple and effective way to reach their target audience for training
          courses and beauty treatments.</h2>
        <h2 class="problem">My goal was to create a company website that encompasses the esthetic services as well as
          the training course information.</h2>
        <p class="disclaimer">Note: to ensure I comply with my non-disclosure agreement I have omitted or changed names,
          designs, logos, etc. in this case study.
          All information in this case study is my own and does not represent the views of Self Image Beauty.</p>
      </div>
    </div>
  </div>
</section>
<div class="container my-role-section">
  <div class="row">
    <div class="col-lg-12" data-aos="fade-left" data-aos-duration="750">
      <h3 class="my-role-head">My Role</h3>
      <p class="my-role-para">I worked with the client one on one to determine the needs of the product.</p>
      <p class="my-role-para">I did all the research, UX & UI design as well as all the code and deployment process and
        hosting for the application.</p>
    </div>
  </div>
</div>
<div class="process-section">
  <div class="container ">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="process-head"> MY PROCESS</h3>
      </div>
      <div class="card text-center" data-aos="fade-right" I data-aos-duration="2000">
        <div class="card-body">
          <i class="fas fa-users"></i>
          <h4 class="my-3">Research</h4>
          <p>Interview client to get a better understanding of the problem we are trying to solve.</p>
        </div>
      </div>
      <div class="card text-center" data-aos="fade-down" data-aos-duration="2000">
        <div class="card-body">
          <i class="fas fa-address-card"></i>
          <h4 class="my-3">Define</h4>
          <p>Create personas, role objectives, decisions, and understand client pain points.</p>
        </div>
      </div>
      <div class="card text-center" data-aos="fade-up" data-aos-duration="2000">
        <div class="card-body">
          <i class="fas fa-lightbulb"></i>
          <h4 class="my-3">Ideate</h4>
          <p>Create sketches, low fidelity wireframes, user flows, and gather client feedback. </p>
        </div>
      </div>
      <div class="card text-center" data-aos="fade-down" data-aos-duration="2000">
        <div class="card-body">
          <i class="fas fa-pencil-ruler"></i>
          <h4 class="my-3">Design</h4>
          <p>Create detailed designs, style guide, and gather client feedback. </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container ideation-container">
  <div class="row">
    <div class="col-lg-12" data-aos="fade-right" data-aos-duration="750">
      <h3 class="my-role-head">Research & Define</h3>
      <p class="my-role-para">Before I started sketching out any design, I sat down with the owners of Self Image Beauty
        to get a better understanding of the product I was to build.</p>
      <p class="my-role-para">After multiple interviews with the client I created a persona based on the user that would
        be visiting the site.</p>
    </div>

  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-12" data-aos="fade-left" data-aos-duration="1000">
      <img class="img-fluid img" src="../../../assets/img/SelfImageBeautyPersona.JPG"
        alt="self image beauty persona example">
    </div>
  </div>
</div>

<div class="ideation">
  <div class="container ideation-container">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-left" data-aos-duration="3000">
        <h3 class="my-role-head">Ideation</h3>
        <p class="my-role-para">In the ideation phase I sketched out ideas, created wireframes and came up with the
          basic idea for the web application.</p>
        <p class="my-role-para">I took these ideas back to the client to gather feedback and ensure we were on the same
          page.</p>
      </div>
    </div>
  </div>
</div>

<div class="wireframes">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-right" data-aos-duration="3000">
        <img class="img-fluid img" src="../../../assets/img/sib-wireframe.JPG" alt="self image beauty wireframe">
      </div>
    </div>
  </div>
</div>

<div class="container ideation-container">
  <div class="row">
    <div class="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
      <h3 class="my-role-head">Design</h3>
      <p class="my-role-para">After completing the wireframes and gathering feedback from the client I started on the
        high-fidelity mocks.</p>
      <p class="my-role-para">After approval from the client I proceeded to built the application out in code and set up
        hosting for the client. </p>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-12" data-aos="fade-left" data-aos-duration="3000">
      <img class="img-fluid img" src="../../../assets/img/sibBig.jpg" alt="high-fidelity mockup">
    </div>
    <div class="col-lg-12" data-aos="fade-right" data-aos-duration="3000">
      <img class="img-fluid img" src="../../../assets/img/sib-home-mobileSmall.jpg" alt="high-fidelity mockup">
    </div>
  </div>
</div>
<div class="ideation">
  <div class="container my-role-section">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-right" data-aos-duration="5000">
        <h3 class="my-role-head">Reflection</h3>
        <p class="my-role-para">I learned a lot through this design process. Mainly it's crucial to research your target
          audience before beginning the design process. </p>
        <p class="my-role-para">I also learned the benefits of having a prototype ready and available for the client to
          check through before beginning development. This allows for less changes after development has started. </p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
