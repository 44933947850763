<div class="aboutme-section" id="about">
  <div class="container">
    <div class="row about-me">
      <div class="col-lg-7" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        <img class="rounded profile-img img-fluid" src="../../../assets/img/profile.png" alt="profile image">
      </div>
      <div class="col-lg-5 text-container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
        <h4 class="hello">Hello, I'm Lina.</h4>
        <p class="profile-text">I believe every great product begins with the user. My passion for problem solving and
          designing user-centric
          products is what got me into UX Design. </p>
        <p class="profile-text">I began my journey into UX as a Software Engineer. I was developing system tools around
          testing frameworks, dashboard analytics, and CI/CD pipelines
          for a large engineering department. It was then I discovered the more we understood our users before we began
          development, the better our products turned out.
        </p>
        <p class="profile-text">Since then, I led and redesigned HealthEquity's internal analytical deployment
          dashboard, that is used by over 38 SCRUM teams and I've continued my
          education into UX through the California Institute of Arts via Coursera.</p>
        <p class="profile-text">When I'm not designing and developing, I enjoy traveling with my husband and daughter
          and I'm an avid digital painter. You can check out some of my digital paintings and travels below!</p>
        <p class="profile-text">Thanks for dropping in!</p>
      </div>
    </div>
  </div>
</div>
<app-whatido></app-whatido>
<section class="about-portfolio">
  <div class="container">
    <div class="row">
      <div class="col-lg-4" data-aos="zoom-in-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/IMG_0011.JPG" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4" data-aos="zoom-in-down" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/IMG_4924.JPG" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4" data-aos="zoom-in-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/IMG_20200713_210046_905.jpg" class="img-fluid img-thumbnail"
          alt="Responsive image">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 bb8" data-aos="zoom-in-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/Ekko_Signed.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4" data-aos="zoom-in-down" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/Harley_Quinn_Signed2.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4 " data-aos="zoom-in-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/Positano3.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" data-aos="zoom-in-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/mamaandbaby.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4" data-aos="zoom-in-down" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/IMG_20180520_120642.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
      <div class="col-lg-4" data-aos="zoom-in-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <img src="../../../assets/img/Hunter-Signed.jpg" class="img-fluid img-thumbnail" alt="Responsive image">
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
