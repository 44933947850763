import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sib',
  templateUrl: './sib.component.html',
  styleUrls: ['./sib.component.scss']
})
export class SibComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
