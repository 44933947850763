<section class="whatido-section" id="services">
  <div class="container">
    <div class="text-center">
      <h2 class="whatido-heading text-uppercase">What I do</h2>
    </div>
    <div class="row text-center end-row">
      <div class="col-md-4" data-aos="fade-right">
        <i class="fas fa-users services-icons"></i>
        <h4 class="my-3">UX Design</h4>
        <p>At the heart of every good product is good user research. Before any product creation I start and end with
          the users by creating personas, journey maps, user testing, etc. to first understand the user that I'm
          building the product for.</p>

      </div>
      <div class="col-md-4 border-side" data-aos="fade-down">
        <i class="fas fa-pencil-ruler services-icons"></i>
        <h4 class="my-3">UI Design</h4>
        <p>I use the top design tools, such as Adobe's Creative Suite, to produce beautiful efficient high fidelity
          mock-ups, prototypes, and wireframes that tailor to the user's needs.</p>

      </div>
      <div class="col-md-4" data-aos="fade-left">
        <i class="fas fa-code services-icons"></i>
        <h4 class="my-3">Clean Code</h4>
        <p>With any product, it's important to have an understanding of clean code architecture. Making sure my code is
          clean and readable for the next developer is at the top of my list when creating new products.</p>
      </div>
    </div>
  </div>
</section>
