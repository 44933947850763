<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 footer-icons">
        <a href="https://www.linkedin.com/in/linafowler/"><i class="fab fa-linkedin"></i></a>
        <a href="https://www.instagram.com/ladylina_designs/"><i class="fab fa-instagram"></i></a>
      </div>
    </div>
    <div class="copywrite">
      <p>2020 &copy; Lina Fowler. All rights reserved</p>
    </div>
  </div>
</div>
