<div class="background-img" id="home">
  <section class="landing landing-background fade-in">
    <div>
      <h2 class="intro">&mdash; Hello, my name is</h2>
      <h1 class="name">LINA</h1>
      <h1 class="name">FOWLER</h1>

      <h1 class="typing">
        <span class="iama">I am a </span>
        <span class="txt-rotate typing" data-period="2000"
          data-rotate='[ "UX & UI Designer", "Software Engineer", "Digital Painter", "Professional Googler"]'>
        </span>
      </h1>
      <span>
        <a class="btn btn-primary landing-btn" href="/#/about">About Me</a>
        <a class="btn btn-primary landing-btn" href="/#/portfolio">Portfolio</a>
      </span>
    </div>
  </section>
</div>
